import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import es from "../locales/es/index.json"
import ca from "../locales/ca/index.json"
import en from "../locales/en/index.json"

export enum Language {
  Spanish = "es",
  Catalonian = "ca",
  English = "en",
}
export const defaultLanguage = Language.Spanish

export const resources = {
  [Language.Spanish]: {
    translation: es,
  },
  [Language.Catalonian]: {
    translation: ca,
  },
  [Language.English]: {
    translation: en,
  },
}

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: defaultLanguage, // Change current language on the end of this file
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  })

const isValidLanguage = (lang: string) => Object.values(Language).includes(lang as Language);

export function getCurrentLanguage(): string {
  const uriLanguage = typeof window !== "undefined"
  ? window.location.pathname.split("/")[1]
  : defaultLanguage
  
  const language = isValidLanguage(uriLanguage) ? uriLanguage : i18n.language;
  if(language !== i18n.language) {
    i18n.changeLanguage(language);
  }
  return language;
}

export function getLanguages(): string[] {
  return Object.keys(resources);
}

export function getAlternativeLanguages(): string[] {
  const currentLanguage = getCurrentLanguage();
  return getLanguages().map((lang) => lang === currentLanguage ? null : lang).filter(Boolean);
}

export function changeCurrentLanguage(language: Language): void {
  i18n.changeLanguage(language);
}
