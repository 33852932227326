import React, { CSSProperties, FC, useEffect } from "react";
import Helmet from "react-helmet";
import favicon from "../../images/favicon.ico";
import logo from "../../images/youplanet-default.png";
import TagManager from "react-gtm-module";

const isProd = process.env.NODE_ENV === `production`;
const gtmContainerId = () =>
  isProd ? process.env.GATSBY_GTM_ID_PRO : process.env.GATSBY_GTM_ID_DEV;

export interface SeoProps {
  description: string;
  lang?: string;
  imageShare?: string;
  title: string;
  slug: string;
}

const SEO: FC<SeoProps> = ({ description, lang, imageShare, title, slug }) => {
  const metaDescription = description || "";
  const siteTitle = title || "";
  const pageTitle = title || "";
  const image = imageShare || logo;

  const tagManagerArgs = {
    gtmId: gtmContainerId() || "Unset",
    dataLayer: {
      event: "pageview",
      page: {
        url: slug,
        title: pageTitle,
      },
    },
  };

  function initializeSecureSmart() {
    var noscript = document.createElement("noscript");
    noscript.innerHTML = `<img alt="" src="https://secure.smart-enterprise-52.com/266878.png" style="display:none;" />`;
    document.body.insertBefore(noscript, document.body.childNodes[0]);
  }

  useEffect(() => {
    TagManager.initialize(tagManagerArgs);
    initializeSecureSmart();
  }, []);

  return (
    <Helmet
      titleTemplate={pageTitle === siteTitle ? siteTitle : `%s | ${siteTitle}`}
      htmlAttributes={{ lang }}
    >
      <meta name="author" content="YouPlanet" />
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <title>{pageTitle}</title>
      <link rel="canonical" href={slug} />
      <link rel="icon" type="image/x-icon" href={favicon} sizes="32x32" />

      <link rel="stylesheet" href="https://use.typekit.net/pcv0med.css"></link>

      <link rel="canonical" href="" />
      <meta name="title" content={pageTitle} />
      <meta name="description" content={metaDescription} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:image" content={image} />
      <meta name="twitter:title" content={pageTitle} />
      <meta name="twitter:description" content={metaDescription} />
      <meta name="twitter:site" content="" />
      <meta name="twitter:creator" content="YouPlanet" />
      <meta property="og:title" content={pageTitle} />
      <meta property="og:image" content={image} />
      <meta property="og:description" content={metaDescription} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={slug} />
      <meta property="og:site_name" content={siteTitle} />
      <meta
        name="google-site-verification"
        content="531htGkMCS59XfgY7QoDeWVsLNP4ytvZHVFtOjsLBeM"
      />
      <script
        type="text/javascript"
        src="https://secure.smart-enterprise-52.com/js/266878.js"
      ></script>

      <script type="application/ld+json">
        {`
          {
            "@context": "https://schema.org",
            "@type": "Organization",
            "url": "",
            "name": "${siteTitle}",
            "contactPoint": {
              "@type": "ContactPoint",
              "telephone": "",
              "contactType": "Customer Support"
            }
          }
        `}
      </script>
    </Helmet>
  );
};

export default SEO;
